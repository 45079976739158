/** ***************************************************************************
 * Molecules / Modal / Contacts
 *************************************************************************** */

.sm-c-modal {
  --sm-c-modal--m-contacts--FontSize: var(--sm-global--FontSize-paragraph--3xs);

  --sm-c-modal--m-contacts--Grid:
    ".       heading close"   1fr
    ".       heading ."       auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;
  --sm-c-modal--m-contacts--md-md--Grid:
    ".       close   ."       1fr
    ".       .       ."       3.75rem  /* 59px */
    ".       heading ."       auto
    ".       heading ."       auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;


  /**
   * Heading
   */

  --sm-c-modal--m-contacts__heading--BlockSize: 2em;  /* 40px */


  /**
   * Content
   */

  --sm-c-modal--m-contacts__content--MarginBlockStart: 1.1em;  /* 22px */


  /**
   * Email icon
   */

  --sm-c-modal--m-contacts__icon--BlockSize: 2.5rem;  /* 41px */


  /**
   * Label
   */

  --sm-c-modal--m-contacts__term--Font: var(--sm-global--Font-label-sm-regular);

  --sm-c-modal--m-contacts__term--MarginBlockEnd: 1.43em;  /* 20px */
  --sm-c-modal--m-contacts__term--md-md--MarginBlockEnd: 1.64em;  /* 23px */

  --sm-c-modal--m-contacts__term--MarginBlockStart: var(--sm-c-modal--m-contacts__term--MarginBlockEnd);
  --sm-c-modal--m-contacts__term--md-md--MarginBlockStart: 2.93em;  /* 41px */


  /**
   * Map button
   */

  --sm-c-modal--m-contacts__button--MarginBlockStart: 1.375rem;  /* 21px-23px */
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal {
    --sm-c-modal--m-contacts--Grid: var(--sm-c-modal--m-contacts--md-md--Grid);
    --sm-c-modal--m-contacts__term--MarginBlockEnd: var(--sm-c-modal--m-contacts__term--md-md--MarginBlockEnd);
    --sm-c-modal--m-contacts__term--MarginBlockStart: var(--sm-c-modal--m-contacts__term--md-md--MarginBlockStart);
  }
}



/**
 * Component
 * 1: reset
 */

.sm-c-modal.sm-m-contacts {
  --sm-c-modal__content--MarginBlockEnd: 0;  /* 1 */
  --sm-c-modal--Grid: var(--sm-c-modal--m-contacts--Grid);
  font-size: var(--sm-c-modal--m-contacts--FontSize);
}



/**
 * Heading
 * 1: reset
 */

.sm-c-modal.sm-m-contacts > h1 {
  align-self: end;
  block-size: var(--sm-c-modal--m-contacts__heading--BlockSize);
  font-size: inherit;  /* 1 */
  grid-area: heading;
  justify-self: center;
  margin: 0;  /* 1 */
}

.sm-c-modal.sm-m-contacts > h1 svg {
  block-size: 100%;
  display: block;  /* 1 */
}

@supports not (block-size: 0) {
  .sm-c-modal.sm-m-contacts > h1 {
    height: var(--sm-c-modal--m-contacts__heading--BlockSize);
  }

  .sm-c-modal.sm-m-contacts > h1 svg {
    height: 100%;
  }
}



/**
 * Email icon
 * 1: hide on small viewports
 */

.sm-c-modal.sm-m-contacts dt > svg {
  --sm-c-icon--BorderColor: transparent;
  block-size: var(--sm-c-modal--m-contacts__icon--BlockSize);
  color: var(--sm-c-modal--AccentColor);
  opacity: 0;  /* 1 */
  pointer-events: none;  /* 1 */
  position: absolute;  /* 1 */
  touch-events: none;  /* 1 */
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal.sm-m-contacts dt > svg {
    opacity: 1;  /* 1 */
    pointer-events: initial;  /* 1 */
    position: initial;  /* 1 */
    touch-events: initial;  /* 1 */
  }
}

@supports not (block-size: 0) {
  .sm-c-modal.sm-m-contacts dt > svg {
    height: var(--sm-c-modal--m-contacts__icon--BlockSize);
  }
}



/**
 * Map button
 */

.sm-c-modal.sm-m-contacts .sm-c-button {
  display: inline-block;
  margin-block-start: var(--sm-c-modal--m-contacts__button--MarginBlockStart);
}

@supports not (margin-block-start: 0) {
  .sm-c-modal.sm-m-contacts .sm-c-button {
    margin-top: var(--sm-c-modal--m-contacts__button--MarginBlockStart);
  }
}



/**
 * Content
 * 1: reset
 */

.sm-c-modal.sm-m-contacts > div {
  margin-block-start: var(--sm-c-modal--m-contacts__content--MarginBlockStart);
}

.sm-c-modal.sm-m-contacts address {
  font: inherit;  /* 1 */
}

.sm-c-modal.sm-m-contacts :is(dl, dt, dd) {
  margin: 0;  /* 1 */
}

.sm-c-modal.sm-m-contacts a {
  text-decoration: none;  /* 1 */
}

@supports not (margin-block-start: 0) {
  .sm-c-modal.sm-m-contacts > div {
    margin-top: var(--sm-c-modal--m-contacts__content--MarginBlockStart);
  }
}



/**
 * Label
 */

.sm-c-modal.sm-m-contacts dt:first-of-type {
  margin-block: 0;
}

.sm-c-modal.sm-m-contacts dt {
  color: var(--sm-c-modal--AccentColor);
  font: var(--sm-c-modal--m-contacts__term--Font);
  margin-block: var(--sm-c-modal--m-contacts__term--MarginBlockStart) var(--sm-c-modal--m-contacts__term--MarginBlockEnd);
  text-transform: uppercase;
}

@supports not (margin-block: 0) {
  .sm-c-modal.sm-m-contacts dt {
    margin-bottom: var(--sm-c-modal--m-contacts__term--MarginBlockEnd);
    margin-top: var(--sm-c-modal--m-contacts__term--MarginBlockStart);
  }
}
