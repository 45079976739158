/** ***************************************************************************
 * Organisms / Page header
 *************************************************************************** */

.sm-c-page-header {
  --sm-c-page-header--AccentColor: var(--sm-c-page--AccentColor);
  --sm-c-page-header--Color: var(--sm-c-page--Color);
  --sm-c-page-header--ColumnGap: 2.5rem;  /* 40px */
  --sm-c-page-header--InsetBlockStart: var(--sm-c-page--PaddingBlockStart);
  --sm-c-page-header--TransitionDuration: var(--sm-global--Duration--sm);

  /**
   * Button container
   */

  --sm-c-page-header__buttons--RowGap: 1.5rem;  /* 25px */

  --sm-c-page-header__buttons--BlockSize: initial;
  --sm-c-page-header__buttons--sm-md--BlockSize: calc(
    100dvh
    - var(--sm-c-page--PaddingBlockStart)
    - var(--sm-c-page__main--MarginBlockStart)
    - 1.75rem);  /* 27px */

  --sm-c-page-header__buttons--InsetBlockStart: initial;
  --sm-c-page-header__buttons--sm-md--InsetBlockStart: calc(
    var(--sm-c-page--PaddingBlockStart)
    + var(--sm-c-page__main--MarginBlockStart));

  --sm-c-page-header__buttons--InsetInlineStart: initial;
  --sm-c-page-header__buttons--sm-md--InsetInlineStart: calc(
    50%
    + var(--sm-c-page--contentwidth) / 2
    + 3 * var(--sm-global--gill-size)
    + 2.5 * var(--sm-global--gill-gap));
  --sm-c-page-header__buttons--sm-2xl--InsetInlineStart: calc(
    50%
    + var(--sm-c-page--contentwidth) / 2
    + 4 * var(--sm-global--gill-size)
    + 3 * var(--sm-global--gill-gap));

  --sm-c-page-header__buttons--Transform: initial;
  --sm-c-page-header__buttons--sm-md--Transform: translateX(-50%);
  --sm-c-page-header__buttons--sm-2xl--Transform: translateX(-100%);


  /**
   * Logo
   */

  --sm-c-page-header__logo--BlockSize: 2.125rem;  /* 34px */
  --sm-c-page-header__logo--md--BlockSize: 2.5rem;  /* 41px */


  /**
   * Divider
   */

  --sm-c-page-header__divider--BlockSize: 2.625rem;  /* 41px */
}

@supports not (height: 100dvh) {
  .sm-c-page-header {
    --sm-c-page-header__buttons--sm-md--BlockSize: calc(
      100vh
      - var(--sm-c-page--PaddingBlockStart)
      - var(--sm-c-page__main--MarginBlockStart)
      - 1.75rem);  /* 27px */
  }
}

/** Inline: medium */
@media all and (min-width: 48rem) {
  .sm-c-page-header {
    --sm-c-page-header__logo--BlockSize: var(--sm-c-page-header__logo--md--BlockSize);
  }
}
/** Block: small */
@media all and (min-height: 31.25rem) {
  /** Inline: medium */
  @media all and (min-width: 48rem) {
    .sm-c-page-header {
      --sm-c-page-header__buttons--BlockSize: var(--sm-c-page-header__buttons--sm-md--BlockSize);
      --sm-c-page-header__buttons--InsetBlockStart: var(--sm-c-page-header__buttons--sm-md--InsetBlockStart);
      --sm-c-page-header__buttons--InsetInlineStart: var(--sm-c-page-header__buttons--sm-md--InsetInlineStart);
      --sm-c-page-header__buttons--Transform: var(--sm-c-page-header__buttons--sm-md--Transform);
    }
  }

  /** Inline: extra extralarge */
  @media all and (min-width: 120rem) {
    .sm-c-page-header {
      --sm-c-page-header__buttons--InsetInlineStart: var(--sm-c-page-header__buttons--sm-2xl--InsetInlineStart);
      --sm-c-page-header__buttons--Transform: var(--sm-c-page-header__buttons--sm-2xl--Transform);
    }
  }
}



/**
 * Component
 * 1: center in the viewport
 */

.sm-c-page-header {
  accent-color: var(--sm-c-page-header--AccentColor);
  color: var(--sm-c-page-header--Color);
  display: grid;
  grid-auto-flow: dense;
  grid-column-gap: var(--sm-c-page-header--ColumnGap);
  grid: "logo . remote . nav" / auto 1fr auto 1fr auto;
  inline-size: max-content;
  inset-block-start: var(--sm-c-page-header--InsetBlockStart);
  inset-inline-start: 50%;  /* 1 */
  position: fixed;
  transform: translateX(-50%);  /* 1 */
  transition: color var(--sm-c-page-header--TransitionDuration) linear;
}

@supports not (inset-block-start: 0) {
  .sm-c-page-header {
    left: 50%;
    top: var(--sm-c-page--PaddingBlockStart);
  }
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  .sm-c-page-header {
    grid:
      "logo"
      "."      6.5vh
      "remote"
      "."      1fr
      "nav";
    inset-block-end: var(--sm-c-page--PaddingBlockEnd);
  }

  @media all and (min-width: 48rem) {
    .sm-c-page-header {
      display: contents;
      position: initial;
    }
  }
}



/**
 * Button container
 * 1: equivalent to:
 *   grid-column: 15 / span 5;
 *   grid-row: content-start;
 *   justify-self: center;
 */

.sm-c-page-header > div {
  block-size: var(--sm-c-page-header__buttons--BlockSize);
  display: contents;
  inset-block-start: var(--sm-c-page-header__buttons--InsetBlockStart);
  inset-inline-start: var(--sm-c-page-header__buttons--InsetInlineStart);
  pointer-events: none;
  touch-events: none;
  transform: var(--sm-c-page-header__buttons--Transform);
}

@supports not (inset-block-start: 0) {
  .sm-c-page-headerr > div {
    height: var(--sm-c-page-header__buttons--BlockSize);  /* 1 */
    left: var(--sm-c-page-header__buttons--InsetInlineStart);
    top: var(--sm-c-page-header__buttons--InsetBlockStart);
  }
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  /** Inline: medium */
  @media all and (min-width: 48rem) {
    .sm-c-page-header > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: fixed;
      row-gap: var(--sm-c-page-header__buttons--RowGap);
    }
  }
}



/**
 * Divider
 * 1: pill-shaped
 */

.sm-c-page-header::before,
.sm-c-page-header::after {
  background-color: var(--sm-c-page-header--AccentColor);
  block-size: var(--sm-c-page-header__divider--BlockSize);
  border-radius: 100vmax;  /* 1 */
  content: "";
  display: block;
  inline-size: 1px;
  justify-self: center;
}

@supports not (inline-size: 0) {
  .sm-c-page-header::before,
  .sm-c-page-header::after {
    height: var(--sm-c-page-header__divider--BlockSize);
    width: 1px;
  }
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  .sm-c-page-header::before,
  .sm-c-page-header::after {
    display: none;
  }
}



/**
 * Logo
 */

.sm-c-page-header > svg {
  block-size: var(--sm-c-page-header__logo--BlockSize);
  display: block;
  grid-area: logo;
  inline-size: auto;
}

@supports not (inline-size: auto) {
  .sm-c-page-header > svg {
    height: var(--sm-c-page-header__logo--BlockSize);
    width: auto;
  }
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  .sm-c-page-header > svg {
    justify-self: center;
  }

  /** Inline: medium */
  @media all and (min-width: 48rem) {
    .sm-c-page-header > svg {
      grid-column: 5 / content-end;
      grid-row: 2;
      justify-self: start;
    }
  }

  /** Inline: extra extra extralarge */
  @media all and (min-width: 120rem) {
    .sm-c-page-header > svg {
      grid-column-start: 4;
    }
  }
}



/**
 * Canvas remote
 */

.sm-c-page-header .sm-c-remote {
  align-self: start;
  grid-area: remote;
  justify-self: center;
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-page-header .sm-c-remote {
    align-self: center;
  }

  /** Inline: extralarge */
  @media all and (min-width: 80rem) {
    .sm-c-page-header .sm-c-remote {
      align-self: end;
    }
  }
}



/**
 * Navigation
 */

.sm-c-page-header .sm-c-nav {
  grid-area: nav;
  justify-self: end;
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  .sm-c-page-header .sm-c-nav {
    justify-self: center;
  }

  /** Inline: medium */
  @media all and (min-width: 48rem) {
    .sm-c-page-header .sm-c-nav {
      align-self: center;
    }
  }

  /** Inline: extralarge */
  @media all and (min-width: 80rem) {
    .sm-c-page-header .sm-c-nav {
      align-self: end;
    }
  }
}
