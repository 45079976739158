/** ***************************************************************************
 * Atoms / Button / Thankful
 *************************************************************************** */

.sm-c-button-thankful {
  --sm-c-button-thankful--Color: var(--sm-global--palette-green);
  --sm-c-button-thankful--Font: var(--sm-global--Font-label-md-regular);
  --sm-c-button-thankful--RowGap: .5em;  /* 8px */


  /**
   * Icon
   */

  --sm-c-button-thankful__icon--BlockSize: 2.25em;  /* 36px */
}



/**
 * Component
 * 1: reset
 */

.sm-c-button-thankful {
  appearance: none;  /* 1 */
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  color: var(--sm-c-button-thankful--Color);
  display: inline-grid;
  font: var(--sm-c-button-thankful--Font);
  grid-row-gap: var(--sm-c-button-thankful--RowGap);
  grid-template-row: repeat(2, auto);
  justify-items: center;
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}



/**
 * Pill
 */

.sm-c-button-thankful .sm-c-button {
  grid-column: 1;
  grid-row: 1;
  visibility: visible;
}

.sm-c-button-thankful:disabled .sm-c-button {
  visibility: hidden;
}




/**
 * Icon
 */

.sm-c-button-thankful > svg {
  block-size: var(--sm-c-button-thankful__icon--BlockSize);
  grid-column: 1;
  grid-row: 1;
  visibility: hidden;
}

.sm-c-button-thankful:disabled > svg {
  visibility: visible;
}

@supports not (block-size: 0) {
  .sm-c-button-thankful > svg {
    height: var(--sm-c-button-thankful__icon--BlockSize);
  }
}


/** Circle */
.sm-c-button-thankful > svg > circle {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  transition:
    stroke-dashoffset
    .3s
    linear;
}

.sm-c-button-thankful:disabled > svg > circle {
  stroke-dashoffset: 0;
}

/** Check mark */
.sm-c-button-thankful > svg > path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  transition:
    stroke-dashoffset
    .3s
    linear
    .3s;
}

.sm-c-button-thankful:disabled > svg > path {
  stroke-dashoffset: 0;
}



/**
 * Thanks
 */

.sm-c-button-thankful > span:last-child {
  opacity: 0;
  transition: opacity .3s linear;
  visibility: hidden;
}

.sm-c-button-thankful:disabled > span:last-child {
  opacity: 1;
  visibility: visible;
}
