/** ***************************************************************************
 * Atoms / Button / Black / Capture
 *************************************************************************** */

@keyframes sm-c-button-black--m-capture__shutter--AnimationName {
  35%, 65% {
    r: 17px;
    stroke-width: 20px;
  }
}

.sm-c-button-black {
  --sm-c-button-black--m-capture__shutter--AnimationDuration: var(--sm-global--Duration--md);
}



/**
 * Shutter
 */

.sm-c-button-black.sm-m-capture:hover > svg > g + circle {
  animation:
    sm-c-button-black--m-capture__shutter--AnimationName
    var(--sm-c-button-black--m-capture__shutter--AnimationDuration)
    var(--sm-c-button-black__icon--TransitionDuration)
    ease;
}
