/** ***************************************************************************
 * Molecules / Navigation
 *************************************************************************** */

.sm-c-nav {
  --sm-c-nav--ColumnGap: 2.25rem;  /* 35px */
  --sm-c-nav--RowGap: 1.5rem;  /* 25px */
}



.sm-c-nav {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--sm-c-nav--ColumnGap);
  grid-row-gap: var(--sm-c-nav--RowGap);
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-nav {
    grid-auto-flow: row;
  }
}



/**
 * Capture button
 */

.sm-c-nav .sm-c-button-black.sm-m-capture {
  display: none;
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-nav .sm-c-button-black.sm-m-capture {
    display: flex;
  }
}
