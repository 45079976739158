/** ***************************************************************************
 * Organisms / CMS plugins / Secret word
 *************************************************************************** */

@keyframes sm-c-secret__button--AnimationName--pulse {
  from {
    box-shadow: 0 0 .77em .22em #3c5cdb;  /* --sm-global--palette-green--40 flattened on --sm-global--palette-blue--100 */
  }

  to {
    box-shadow: 0 0 .77em .22em #0acfb3;  /* --sm-global--palette-green--90 flattened on --sm-global--palette-blue--100 */
  }
}

@keyframes sm-c-secret__letter--AnimationName--pulse {
  from {
    box-shadow: 0 0 0 calc(var(--sm-c-secret--ColumnGap) + var(--sm-c-secret__letter--BorderWidth)) transparent;
  }

  to {
    box-shadow: 0 0 0 calc(var(--sm-c-secret--ColumnGap) + var(--sm-c-secret__letter--BorderWidth)) var(--sm-global--palette-fog--25);
  }
}

.sm-c-secret {
  --sm-c-secret--ColumnGap: .08em;  /* eyeballed */

  --sm-c-secret--MarginInlineEnd: 0;
  --sm-c-secret--sm--md--MarginInlineEnd: -.2em;  /* eyeballed */


  /**
   * Hidden letter
   */

  --sm-c-secret__letter--BlockSize: .93em;

  --sm-c-secret--focus__letter--AnimationDuration: var(--sm-global--Duration--md);
  --sm-c-secret--focus__letter--AnimationIterationCount: 6;

  --sm-c-secret__letter--BorderColor: var(--sm-global--palette-white--65);

  --sm-c-secret__letter--BorderWidth: 1px;
  --sm-c-secret__letter--lg--BorderWidth: 2px;

  --sm-c-secret--m-reveal__letter--TransitionDelay: 300ms;
  --sm-c-secret--m-reveal__letter--TransitionDuration: var(--sm-global--Duration--sm);
  --sm-c-secret--m-reveal__letter--Color: var(--sm-c-page--AccentColor);
  --sm-c-secret--m-reveal__letter--FontFamily: var(--sm-global--FontFamily-special);
  --sm-c-secret--m-reveal__letter--FontWeight: var(--sm-global--FontWeight-bold);


  /**
   * Dialog button
   */

  --sm-c-secret__button--AnimationDuration: var(--sm-global--Duration--md);
  --sm-c-secret__button--BackgroundColor: #5229EE;  /* --sm-global--palette-indigo--80 flattened on --sm-global--palette-green--90 */
  --sm-c-secret__button--BlockSize: 2.11em;  /* 38px */
  --sm-c-secret__button--Border: calc(1em / 3) solid var(--sm-global--palette-green);
  --sm-c-secret__button--BoxShadow: 0 0 .77em .22em #3c5cdb;  /* --sm-global--palette-green--40 flattened on --sm-global--palette-blue--100 */
  --sm-c-secret__button--FontFamily: var(--sm-global--FontFamily-default);
  --sm-c-secret__button--FontWeight: var(--sm-global--FontWeight-medium);
  --sm-c-secret__button--MarginInline: .83em;  /* 15px */

  --sm-c-secret__button--FontSize: 1.125rem;  /* 18px */
  --sm-c-secret__button--sm-md--FontSize: max(.61rem, min(1.27vw, 1.125rem));  /* clamp(.61rem, 1.27vw, 1.125rem) */
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-secret {
    --sm-c-secret--MarginInlineEnd: var(--sm-c-secret--sm--md--MarginInlineEnd);
    --sm-c-secret__button--FontSize: var(--sm-c-secret__button--sm-md--FontSize);
  }
}

/** Inline: large */
@media all and (min-width: 64rem) {
  .sm-c-secret {
    --sm-c-secret__letter--BorderWidth: var(--sm-c-secret__letter--lg--BorderWidth);
  }
}


/**
 * Component
 */

.sm-c-secret {

}

.sm-c-secret.sm-m-js {
  column-gap: var(--sm-c-secret--ColumnGap);
  display: inline-flex;
  margin-inline-end: var(--sm-c-secret--MarginInlineEnd);;
  vertical-align: middle;
}



/**
 * Hidden letter
 * 1: reset
 * 2: center content
 * 3: adjust vertical alignment
 * 4: stagger animation. must come after animation
 */

.sm-c-secret u {
  align-items: center;  /* 2 */
  aspect-ratio: 1 / 1;
  block-size: var(--sm-c-secret__letter--BlockSize);
  border-radius: 50%;
  border: var(--sm-c-secret__letter--BorderWidth) solid var(--sm-c-secret__letter--BorderColor);
  box-shadow: 0 0 0 calc(var(--sm-c-secret--ColumnGap) + var(--sm-c-secret__letter--BorderWidth)) transparent;
  box-sizing: border-box;
  display: inline-flex;  /* 2 */
  justify-content: center;  /* 2 */
  text-decoration: inherit;  /* 1 */
  transition:
    background-color
    var(--sm-c-secret--m-reveal__letter--TransitionDuration)
    linear
    calc(
      var(--sm-c-secret--m-reveal__letter--TransitionDuration) / 2
      + var(--sm-c-secret__letter--index, 0) * var(--sm-c-secret--m-reveal__letter--TransitionDelay)
    );
}

.sm-c-secret u::before {
  color: var(--sm-c-secret--m-reveal__letter--Color);
  content: attr(title);
  font-family: var(--sm-c-secret--m-reveal__letter--FontFamily);
  font-weight: var(--sm-c-secret--m-reveal__letter--FontWeight);
  margin-block-start: .2em;  /* 3 */
  opacity: 0;
  text-transform: uppercase;
  transition:
    opacity
    var(--sm-c-secret--m-reveal__letter--TransitionDuration)
    linear
    calc(
      var(--sm-c-secret--m-reveal__letter--TransitionDuration) / 2
      + var(--sm-c-secret__letter--index, 0) * var(--sm-c-secret--m-reveal__letter--TransitionDelay)
    );
}

.sm-c-secret:focus-within u {
  animation:
    var(--sm-c-secret--focus__letter--AnimationDuration)
    linear
    var(--sm-c-secret--focus__letter--AnimationIterationCount)
    alternate
    sm-c-secret__letter--AnimationName--pulse;
}

.sm-c-secret.sm-m-reveal u {
  background-color: var(--sm-c-page--BackgroundColor);
}

.sm-c-secret.sm-m-reveal u::before {
  opacity: 1;

}

@supports not (aspect-ratio: 1 / 1) {
  .sm-c-secret u {
    height: var(--sm-c-secret__letter--BlockSize);
    width: var(--sm-c-secret__letter--BlockSize);
  }
}

@supports not (block-size: 0) {
  .sm-c-secret u {
    height: var(--sm-c-secret__letter--BlockSize);
  }

  .sm-c-secret u::before {
    margin-top: .2em;  /* 3 */
  }
}



/**
 * Content
 */

.sm-c-secret span {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  touch-events: none;
}



/**
 * Dialog button
 * 1: reset
 * 2: take into account the space before (eyeballed)
 */

.sm-c-secret button {
  animation: sm-c-secret__button--AnimationName--pulse var(--sm-c-secret__button--AnimationDuration) linear infinite alternate;
  appearance: none;  /* 1 */
  aspect-ratio: 1 / 1;
  background-color: var(--sm-c-secret__button--BackgroundColor);
  block-size: var(--sm-c-secret__button--BlockSize);
  border-radius: 50%;
  border: var(--sm-c-secret__button--Border);
  box-shadow: var(--sm-c-secret__button--BoxShadow);
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: block;
  font: var(--sm-c-secret__button--FontWeight) var(--sm-c-secret__button--FontSize) var(--sm-c-secret__button--FontFamily);
  margin-block: 0;  /* 1 */
  margin-inline:
    calc(var(--sm-c-secret__button--MarginInline) - .4ch)  /* 2 */
    var(--sm-c-secret__button--MarginInline);
  order: -1;
  padding: 0;  /* 1 */
  z-index: 1;
}

.sm-c-secret button:hover {
  animation-play-state: paused;
}

.sm-c-secret button:focus {
  outline: 0;  /* 1 */
}

/** a secret modal is open or the user clicked on answer */
.sm-m-pause .sm-c-secret button {
  animation: none;
}

.sm-m-drawing .sm-c-secret button {
  pointer-events: none;
  touch-events: none;
}

@supports not (aspect-ratio: 1 / 1) {
  .sm-c-secret button {
    height: var(--sm-c-secret__button--BlockSize);
    width: var(--sm-c-secret__button--BlockSize);
  }
}

@supports not (block-size: 0) {
  .sm-c-secret button {
    height: var(--sm-c-secret__button--BlockSize);
    margin: 0 var(--sm-c-secret__button--MarginInline) 0 calc(var(--sm-c-secret__button--MarginInline) - .4ch);  /* 2 */
  }
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-secret button {
    order: initial;
    margin-inline-start: calc(var(--sm-c-secret__button--BlockSize) / -4 + .3em);
    margin-inline-end: 0;
    margin-block-start: calc(var(--sm-c-secret__button--BlockSize) / -2 - .2em);
  }

  @supports not (margin-inline-start: 0) {
    .sm-c-secret button {
      margin-left: calc(var(--sm-c-secret__button--BlockSize) / -4 + .3em);
      margin-right: 0;
      margin-top: calc(var(--sm-c-secret__button--BlockSize) / -2 - .2em);
    }
  }
}
