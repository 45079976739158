/** ***************************************************************************
 * Molecules / Modal / Capture
 *************************************************************************** */

.sm-c-modal {
  --sm-c-modal--m-capture--Font: var(--sm-global--Font-paragraph-3xs-medium);

  --sm-c-modal--m-capture--Grid:
    ".       .       close"   1fr
    ".       heading close"   auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;
  --sm-c-modal--m-capture--md-md--Grid:
    ".       close   ."       1fr
    ".       .       ."       3.75rem  /* 59px */
    ".       heading ."       auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;


  /**
   * Heading
   */

  --sm-c-modal--m-capture__heading--Color: var(--sm-c-modal--AccentColor);
  --sm-c-modal--m-capture__heading--Font: var(--sm-global--Font-label-sm-regular);


  /**
   * Icon
   */

  --sm-c-modal--m-capture__icon--BlockSize: 2.93em;  /* 41px */
  --sm-c-modal--m-capture__icon--MarginBlockEnd: 0;


  /**
   * Content
   */

  --sm-c-modal--m-capture__content--LineHeight: 1.5;
  --sm-c-modal--m-capture__content--MarginBlockStart: 1.3em;  /* 26px */


  /**
   * Map button
   */

  --sm-c-modal--m-capture__button--MarginBlockStart: 3.19rem;  /* 51px */
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal {
    --sm-c-modal--m-capture--Grid: var(--sm-c-modal--m-capture--md-md--Grid);
    --sm-c-modal--m-capture__term--MarginBlockEnd: var(--sm-c-modal--m-capture__term--md-md--MarginBlockEnd);
    --sm-c-modal--m-capture__term--MarginBlockStart: var(--sm-c-modal--m-capture__term--md-md--MarginBlockStart);
  }
}



/**
 * Component
 * 1: reset
 */

.sm-c-modal.sm-m-capture {
  --sm-c-modal--Font: var(--sm-c-modal--m-capture--Font);
  --sm-c-modal--Grid: var(--sm-c-modal--m-capture--Grid);
  --sm-c-modal__content--MarginBlockEnd: 0;  /* 1 */
  --sm-c-modal__content--LineHeight: var(--sm-c-modal--m-capture__content--LineHeight);
}



/**
 * Heading
 */

.sm-c-modal.sm-m-capture h1 {
  color: var(--sm-c-modal--m-capture__heading--Color);
  font: var(--sm-c-modal--m-capture__heading--Font);
  grid-area: heading;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}



/**
 * Icon
 */

.sm-c-modal.sm-m-capture h1 svg {
  --sm-c-icon--BorderColor: transparent;
  block-size: var(--sm-c-modal--m-capture__icon--BlockSize);
  display: block;
  margin-block-end: var(--sm-c-modal--m-capture__icon--MarginBlockEnd);
  margin-inline: auto;
}

@supports not (block-size: 0) {
  .sm-c-modal.sm-m-capture h1 svg {
    height: var(--sm-c-modal--m-capture__icon--BlockSize);
    margin-bottom: var(--sm-c-modal--m-capture__icon--MarginBlockEnd);
    margin-left: auto;
    margin-right: auto;
  }
}



/**
 * Button
 */

.sm-c-modal.sm-m-capture .sm-c-button-thankful {
  margin-block-start: var(--sm-c-modal--m-capture__button--MarginBlockStart);
}

@supports not (margin-block-start: 0) {
  .sm-c-modal.sm-m-capture .sm-c-button-thankful {
    margin-top: var(--sm-c-modal--m-capture__button--MarginBlockStart);
  }
}



/**
 * Content
 * 1: reset
 */

.sm-c-modal.sm-m-capture > div {
  margin-block-start: var(--sm-c-modal--m-capture__content--MarginBlockStart);
}

@supports not (margin-block-start: 0) {
  .sm-c-modal.sm-m-capture > div {
    margin-top: var(--sm-c-modal--m-capture__content--MarginBlockStart);
  }
}
