/** ***************************************************************************
 * Atoms / Button / Modal / Close
 *************************************************************************** */

.sm-c-modal-button.sm-m-close {
  --sm-c-modal-button--m-close--RowGap: .71em;  /* 10px */


  /**
   * Icon
   */

  --sm-c-modal-button--m-close__icon--TransitionDuration: var(--sm-global--Duration--sm);
  --sm-c-modal-button--m-close--hover__icon--Transform: scale(1.1);  /* 42px -> 46px */
}



/**
 * Component
 * 1: contain icon border
 * 2: disabled, see Icon (1)
 */

.sm-c-modal-button.sm-m-close {
  /* row-gap: var(--sm-c-modal-button--m-close--RowGap); */  /* 2 */
}



/**
 * Label
 * 1: hide
 */

.sm-c-modal-button.sm-m-close > span:last-child {
  opacity: 0;  /* 1 */
  pointer-events: none;  /* 1 */
  position: absolute;  /* 1 */
  touch-events: none;  /* 1 */
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal-button.sm-m-close > span:last-child {
    opacity: 1;
    pointer-events: initial;
    position: static;
  }
}



/**
 * Icon
 * 1: move down by icon border width
 */

.sm-c-modal-button.sm-m-close svg {
  --sm-c-icon--BackgroundColor: transparent;
  --sm-c-icon--Color: currentcolor;
  margin-block-start: 1px;  /* 1 */
}

@supports not (margin-block-start: 0) {
  .sm-c-modal-button.sm-m-close svg {
    margin-top: 1px;  /* 1 */
  }
}



/**
 * icon
 */

.sm-c-modal-button.sm-m-close > :first-child {
  position: relative;
}


/**
 * Icon border
 */

.sm-c-modal-button.sm-m-close > :first-child::before {
  block-size: var(--sm-c-modal-button__icon--BlockSize);
  border-radius: 50%;
  border: 1px solid currentcolor;
  content: "";
  display: block;
  inline-size: var(--sm-c-modal-button__icon--BlockSize);
  inset-block-start: 0;
  inset-inline-start: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: transform var(--sm-c-modal-button--m-close__icon--TransitionDuration) ease;
}

.sm-c-modal-button.sm-m-close:hover > :first-child::before {
  transform: translateX(-50%) var(--sm-c-modal-button--m-close--hover__icon--Transform);
}

@supports not (inline-size: 0) {
  .sm-c-modal-button.sm-m-close > :first-child::before {
    height: var(--sm-c-modal-button__icon--BlockSize);
    width: var(--sm-c-modal-button__icon--BlockSize);
    top: 0;
    left: 50%;
  }
}
