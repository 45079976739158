/** ***************************************************************************
 * Atoms / Button / Canvas remote / Base
 *************************************************************************** */

.sm-c-remote-button {
  --sm-c-remote-button--Color: currentcolor;
  --sm-c-remote-button--ColumnGap: 1em;  /* 12px */
  --sm-c-remote-button--RowGap: .5em;  /* 7px */

  --sm-c-remote-button--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--2xs) var(--sm-global--FontFamily-default);
  --sm-c-remote-button--md--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--xs) var(--sm-global--FontFamily-default);

  /**
   * Icon
   */

  --sm-c-remote-button__icon--BlockSize: 3.5em;  /* 41px */

  --sm-c-remote-button--checked__icon--Color: var(--sm-c-page--BackgroundColor);


  /**
   * Pictogram
   */

  --sm-c-remote-button--hover__pictogram--TransitionDuration: var(--sm-global--Duration--xs);


  /**
   * Line
   */

  --sm-c-remote-button__line--TransitionDuration: var(--sm-global--Duration--tn);
}

/** Inline: medium */
@media all and (min-width: 48rem) {
  .sm-c-remote-button {
    --sm-c-remote-button--Font: var(--sm-c-remote-button--md--Font);
  }
}


/**
 * Component
 */

.sm-c-remote-button {
  cursor: pointer;
  display: inline-grid;
  font: var(--sm-c-remote-button--Font);
  grid-auto-flow: row;
  grid-gap: var(--sm-c-remote-button--RowGap) var(--sm-c-remote-button--ColumnGap);
  place-items: center;
  text-transform: uppercase;
}

.sm-m-drawing .sm-c-remote-button {
  pointer-events: none;
  touch-events: none;
}




/**
 * Radio button
 * 1: hide
 */

.sm-c-remote-button input {
  opacity: 0;  /* 1 */
  pointer-events: none;  /* 1 */
  position: absolute;  /* 1 */
  touch-events: none;  /* 1 */
}



/**
 * Icon
 */

.sm-c-remote-button svg {
  --sm-c-remote-button__icon--BackgroundColor: var(--sm-c-page--BackgroundColor);
  aspect-ratio: 1;
  block-size: var(--sm-c-remote-button__icon--BlockSize);
  grid-column: 1;
  grid-row: 1;
}

.sm-c-remote-button input:checked ~ svg {
  --sm-c-remote-button__icon--BackgroundColor: currentcolor;
  --sm-c-remote-button__icon--BorderColor: currentcolor;
  --sm-c-remote-button__icon--Color: var(--sm-c-remote-button--checked__icon--Color);
}

@supports not (block-size: 0) {
  .sm-c-remote-button svg {
    height: var(--sm-c-remote-button__icon--BlockSize);
  }
}

@supports not (aspect-ratio: 1) {
  .sm-c-remote-button svg {
    height: var(--sm-c-remote-button__icon--BlockSize);
    width: var(--sm-c-remote-button__icon--BlockSize);
  }
}



/**
 * Pictogram
 */

.sm-c-remote-button > svg > path:first-of-type {
  transform-origin: var(--sm-c-remote-button__pictogram--TransformOrigin);
  transition:
    transform
    var(--sm-c-remote-button--hover__pictogram--TransitionDuration)
    linear;
}

.sm-c-remote-button:hover input:not(:checked) ~ svg > path:first-of-type {
  transform: rotateZ(-90deg);
  transition-delay: var(--sm-c-remote-button__line--TransitionDuration);
}



/**
 * Line
 */

.sm-c-remote-button svg > path:nth-of-type(2) {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  transition:
    stroke-dashoffset
    var(--sm-c-remote-button__line--TransitionDuration)
    linear
    var(--sm-c-remote-button--hover__pictogram--TransitionDuration);
}

.sm-c-remote-button:hover input:not(:checked) ~ svg > path:nth-of-type(2) {
  stroke-dashoffset: 1;
  transition-delay: 0s;
}
