/** ***************************************************************************
 * Molecules / Canvas
 *************************************************************************** */

.sm-c-canvas {
  --sm-c-canvas--BackgroundColor: transparent;
  --sm-c-canvas--Color: var(--sm-global--palette-green);
  --sm-c-canvas--Cursor: initial;
}



/**
 * Component
 */

.sm-c-canvas {
  background-color: var(--sm-c-canvas--BackgroundColor);
  color: var(--sm-c-canvas--Color);
  cursor: var(--sm-c-canvas--Cursor);
  display: block;
}
