/** ***************************************************************************
 * Atoms / Button / Modal / Answer
 *************************************************************************** */

.sm-c-modal-button.sm-m-answer {
  /**
   * Pictogram
   */

  --sm-c-modal-button--hover__pictogram--TransitionDuration: var(--sm-global--Duration--xs);


  /**
   * Line
   */

  --sm-c-modal-button__line--TransitionDuration: var(--sm-global--Duration--tn);
}



/**
 * Component
 */

.sm-c-modal-button.sm-m-answer {

}



/**
 * Pictogram
 */

.sm-c-modal-button.sm-m-answer > svg > path:first-of-type {
  transform-origin: var(--sm-c-modal-button__pictogram--TransformOrigin);
  transition:
    transform
    var(--sm-c-modal-button--hover__pictogram--TransitionDuration)
    linear;
}

.sm-c-modal-button.sm-m-answer:hover > svg > path:first-of-type {
  transform: rotateZ(-90deg);
  transition-delay: var(--sm-c-modal-button__line--TransitionDuration);
}



/**
 * Line
 */

.sm-c-modal-button.sm-m-answer svg > path:nth-of-type(2) {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  transition:
    stroke-dashoffset
    var(--sm-c-modal-button__line--TransitionDuration)
    linear
    var(--sm-c-modal-button--hover__pictogram--TransitionDuration);
}

.sm-c-modal-button.sm-m-answer:hover svg > path:nth-of-type(2) {
  stroke-dashoffset: 1;
  transition-delay: 0s;
}
