/** ***************************************************************************
 * Molecules / Canvas remote
 *************************************************************************** */

.sm-c-remote {
  --sm-c-remote--ColumnGap: 2.25rem;  /* 35px */

  --sm-c-remote--BackgroundColor: transparent;
  --sm-c-remote--sm-lg--BackgroundColor: var(--sm-c-page--BackgroundColor);

  --sm-c-remote--GridAutoColumns: auto;
  --sm-c-remote--sm-lg--GridAutoColumns: minmax(4rem, max-content);  /* 63px */

  --sm-c-remote--GridAutoFlow: column;
  --sm-c-remote--sm-md--GridAutoFlow: row;

  --sm-c-remote--BorderRadius: 0;
  --sm-c-remote--sm-lg--BorderRadius: 100vmax;

  --sm-c-remote--Border: 0;
  --sm-c-remote--sm-lg--Border: 1px solid currentcolor;

  --sm-c-remote--PaddingBlock: 0;
  --sm-c-remote--sm-lg--PaddingBlock: 2rem;  /* 30px-33px */

  --sm-c-remote--PaddingInline: 0;
  --sm-c-remote--sm-lg--PaddingInline: .69rem;  /* 11px */

  --sm-c-remote--RowGap: .5625rem;  /* 9px */
  --sm-c-remote--sm-lg--RowGap: 1.5rem;  /* 25px */
}

/**
 * Block: small / Inline: medium
 */

@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  .sm-c-remote {
    --sm-c-remote--GridAutoFlow: var(--sm-c-remote--sm-md--GridAutoFlow);
  }
}

/**
 * Block: small / Inline: large
 */

@media all and (min-height: 31.25rem) and (min-width: 64rem) {
  .sm-c-remote {
    --sm-c-remote--BackgroundColor: var(--sm-c-remote--sm-lg--BackgroundColor);
    --sm-c-remote--Border: var(--sm-c-remote--sm-lg--Border);
    --sm-c-remote--BorderRadius: var(--sm-c-remote--sm-lg--BorderRadius);
    --sm-c-remote--GridAutoColumns: var(--sm-c-remote--sm-lg--GridAutoColumns);
    --sm-c-remote--PaddingBlock: var(--sm-c-remote--sm-lg--PaddingBlock);
    --sm-c-remote--PaddingInline: var(--sm-c-remote--sm-lg--PaddingInline);
    --sm-c-remote--RowGap: var(--sm-c-remote--sm-lg--RowGap);
  }
}



/**
 * Component
 * 1: hide when javascript in not available
 */

.sm-c-remote {
  background-color: var(--sm-c-remote--BackgroundColor);
  border-radius: var(--sm-c-remote--BorderRadius);
  border: var(--sm-c-remote--Border);
  display: grid;  /* 1 */
  grid-auto-columns: var(--sm-c-remote--GridAutoColumns);
  grid-auto-flow: var(--sm-c-remote--GridAutoFlow);
  grid-gap: var(--sm-c-remote--RowGap) var(--sm-c-remote--ColumnGap);
  padding-block: var(--sm-c-remote--PaddingBlock);
  padding-inline: var(--sm-c-remote--PaddingInline);
}

.sm-c-remote.sm-m-js {
  display: inline-grid;  /* 1 */
}

@supports not (padding-block: var(--sm-c-remote--PaddingBlock)) {
  .sm-c-remote {
    padding: var(--sm-c-remote--PaddingBlock) var(--sm-c-remote--PaddingInline);
  }
}



/**
 * Drag button
 * 1: only show on touch screens
 */

.sm-c-remote > .sm-c-remote-button:nth-child(1) {
  display: none;  /* 1 */
}

@media (pointer: coarse) {
  .sm-c-remote > .sm-c-remote-button:nth-child(1) {
    display: inherit;  /* 1 */
  }
}
