/** ***************************************************************************
 * Atoms / Button / Modal / Peek
 *************************************************************************** */

@keyframes sm-c-modal-button--m-peek__pupil--AnimationName {
  0% {
    transform: initial;
  }
  15% {
    transform: translateX(-40px) translateY(10px);
  }
  25% {
    transform: initial;
  }
  40% {
    transform: translateX(40px) translateY(10px);
  }
  50%, 100% {
    transform: initial;
  }
}

.sm-c-modal-button.sm-m-peek {
  /**
   * Pupil
   */

  --sm-c-modal-button--m-peek__pupil--AnimationDuration: calc(var(--sm-global--Duration--lg) * 2);
}



/**
 * Pupil
 */



.sm-c-modal-button.sm-m-peek:hover > svg > circle:last-of-type {
  animation: sm-c-modal-button--m-peek__pupil--AnimationName var(--sm-c-modal-button--m-peek__pupil--AnimationDuration) ease infinite;
}
