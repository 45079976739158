/** ***************************************************************************
 * Atoms / Button / Black / Base
 *************************************************************************** */

.sm-c-button-black {
  --sm-c-button-black--AccentColor: var(--sm-c-page--AccentColor);
  --sm-c-button-black--Color: var(--sm-c-page--Color);
  --sm-c-button-black--RowGap: .5em;  /* 7px */

  --sm-c-button-black--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--2xs) var(--sm-global--FontFamily-default);
  --sm-c-button-black--md--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--xs) var(--sm-global--FontFamily-default);


  /**
   * Icon
   */

  --sm-c-button-black__icon--BackgroundColor: #4c169f;  /* --sm-global--palette-gray--50 flattened on --sm-global--palette-indigo */
  --sm-c-button-black__icon--BlockSize: 3.5em;  /* 41px */
  --sm-c-button-black__icon--TransitionDuration: var(--sm-global--Duration--tn);
}

/** Inline: medium */
@media all and (min-width: 48rem) {
  .sm-c-button-black {
    --sm-c-button-black--Font: var(--sm-c-button-black--md--Font);
  }
}



/**
 * Component
 * 1: simulate flex gap (see icon)
 * 2: reset
 */

.sm-c-button-black {
  /* row-gap: var(--sm-c-button-black--RowGap); */  /* 1 */
  appearance: none;  /* 2 */
  border: 0;  /* 2 */
  background-color: transparent;  /* 2 */
  accent-color: var(--sm-c-button-black--AccentColor);
  align-items: center;
  color: var(--sm-c-button-black--Color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font: var(--sm-c-button-black--Font);
  padding: 0;  /* 2 */
  text-decoration: none;
  text-transform: uppercase;
}

.sm-c-button-black:focus {
  outline: 0;  /* 2 (not a very bright idea) */
}

.sm-m-drawing .sm-c-button-black {
  pointer-events: none;
  touch-events: none;
}


/**
 * Icon
 * 1: simulate flex gap (see component)
 */

.sm-c-button-black > svg {
  --sm-c-icon--BackgroundColor: var(--sm-c-button-black__icon--BackgroundColor);
  --sm-c-icon--BorderColor: var(--sm-c-icon--BackgroundColor);
  block-size: var(--sm-c-button-black__icon--BlockSize);
  inline-size: auto;
  margin-block-end: var(--sm-c-button-black--RowGap);  /* 1 */
}

.sm-c-button-black > * {
  transition: all var(--sm-c-button-black--TransitionDuration) linear;
}

.sm-c-button-black:hover > svg {
  --sm-c-icon--BackgroundColor: var(--sm-c-button-black--Color);
  --sm-c-icon--BorderColor: currentcolor;
  color: var(--sm-c-page--BackgroundColor);
}

.sm-c-button-black > svg > circle:first-child {
  transition: fill var(--sm-c-button-black__icon--TransitionDuration) linear;
}

@supports not (block-size: 0) {
  .sm-c-button-black > svg {
    height: var(--sm-c-button-black__icon--BlockSize);
    margin-bottom: var(--sm-c-button-black--RowGap);  /* 1 */
    width: auto;
  }
}
