/** ***************************************************************************
 * Atoms / Button / Pill
 *************************************************************************** */

.sm-c-button {
  --sm-c-button--BackgroundColor: var(--sm-global--palette-gray);
  --sm-c-button--BorderColor: var(--sm-c-button--Color);
  --sm-c-button--BorderWidth: calc(1em / 14);  /* 1px */
  --sm-c-button--Color: var(--sm-global--palette-green);
  --sm-c-button--Font: var(--sm-global--Font-label-sm-regular);
  --sm-c-button--PaddingBlock: .64em;  /* 9px */
  --sm-c-button--PaddingInline: 1.28em;  /* 18px */
  --sm-c-button--TransitionDuration: var(--sm-global--Duration--xs);

  --sm-c-button--hover--BackgroundColor: var(--sm-c-button--Color);
  --sm-c-button--hover--Color: var(--sm-c-button--BackgroundColor);
}



/**
 * Copmponent
 * 1: reset
 * 2: pill-shaped
 */

.sm-c-button {
  appearance: none;  /* 1 */
  background-color: var(--sm-c-button--BackgroundColor);
  border-radius: 100vmax;  /* 2 */
  border: var(--sm-c-button--BorderWidth) solid var(--sm-c-button--BorderColor);
  color: var(--sm-c-button--Color);
  cursor: pointer;
  font: var(--sm-c-button--Font);
  margin: 0;  /* 1 */
  padding-block: var(--sm-c-button--PaddingBlock);
  padding-inline: var(--sm-c-button--PaddingInline);
  text-decoration: none;  /* 1 */
  transition:
    background-color var(--sm-c-button--TransitionDuration) linear,
    border-color var(--sm-c-button--TransitionDuration) linear,
    color var(--sm-c-button--TransitionDuration) linear;
}

.sm-c-button:hover {
  background-color: var(--sm-c-button--hover--BackgroundColor);
  border-color: var(--sm-c-button--hover--BorderColor);
  color: var(--sm-c-button--hover--Color);
}

.sm-m-drawing .sm-c-button {
  pointer-events: none;
  touch-events: none;
}

@supports not (padding-block: 0) {
  .sm-c-button {
    padding: var(--sm-c-button--PaddingBlock) var(--sm-c-button--PaddingInline);
  }
}
