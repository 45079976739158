/** ***********************************************************************************
 * Strong text styles
 *********************************************************************************** */

body {
  --sm-c-page__strong--FontWeight: var(--sm-global--FontWeight-bold);
}



b,
strong {
  font-weight: var(--sm-c-page__strong--FontWeight);
}
