/** ***************************************************************************
 * Organisms / Forms / Fieldset
 *************************************************************************** */

.sm-c-fieldset {

}



/**
 * Component
 * 1: reset
 */

.sm-c-fieldset {
  border: 0;  /* 1 */
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}
