/** ***************************************************************************
 * Molecules / Modal / Base
 *************************************************************************** */

.sm-c-modal {
  --sm-c-modal--AccentColor: var(--sm-global--palette-green);
  --sm-c-modal--Color: var(--sm-global--palette-white);
  --sm-c-modal--Font: var(--sm-global--Font-dynamic-sm-regular);
  --sm-c-modal--LineHeight: 1.06;
  --sm-c-modal--Padding: 2rem;  /* 33px */

  --sm-c-modal--ColumnGap: 0;
  --sm-c-modal--md-md--ColumnGap: 4.875rem;  /* 78px */

  --sm-c-modal--Grid:
    ".       heading close"   1fr
    ".       heading ."       auto
    "content content content" auto
    ".       .       ."       1fr
  / 1fr      auto    1fr;


  /**
   * Background
   */

  --sm-c-modal__background--BackgroundColor: var(--sm-c-page--BackgroundColor);
  --sm-c-modal__background--TransitionDuration: var(--sm-global--Duration--md);


  /**
   * Mask
   */

  --sm-c-modal__mask--BackgroundColor: var(--sm-global--palette-gray);;
  --sm-c-modal__mask--TransitionDuration: calc(2 * var(--sm-c-modal__background--TransitionDuration));
  --sm-c-modal__mask--TransitionTimingFunction: var(--sm-global--AnimationTimingFunction--bounce);

  --sm-c-modal__mask--InlineSize: 200vmax;
  --sm-c-modal__mask--md-md--InlineSize: max(55.125rem, min(86.18vw, 94.375rem));  /*clamp(55.125rem, 86.18vw, 94.375rem)*/ /* 882px / 1510px */


  /**
   * Foreground
   */

  --sm-c-modal__foreground--TransitionDuration: var(--sm-global--Duration--sm);


  /**
   * Content
   */

  --sm-c-modal__content--MaxInlineSize: 34ch;
  --sm-c-modal__content--MarginBlockEnd: 2.5rem;  /* 39px */
  --sm-c-modal__content--LineHeight: 1.1em;


  /**
   * Close button
   */

  --sm-c-modal__close--JustifySelf: end;
  --sm-c-modal__close--md-md--JustifySelf: center;

  --sm-c-modal__close--AlignSelf: start;
  --sm-c-modal__close--md-md--AlignSelf: end;


  /**
   * Logo
   */

  --sm-c-modal__logo--BlockSize: 3.125rem;  /* 50px */
  --sm-c-modal__logo--Color: var(--sm-c-modal--AccentColor);
}

/** Inline: medium / Block: medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal {
    --sm-c-modal--ColumnGap: var(--sm-c-modal--md-md--ColumnGap);
    --sm-c-modal__mask--InlineSize: var(--sm-c-modal__mask--md-md--InlineSize);
    --sm-c-modal__close--JustifySelf: var(--sm-c-modal__close--md-md--JustifySelf);
    --sm-c-modal__close--AlignSelf: var(--sm-c-modal__close--md-md--AlignSelf);
  }
}



/**
 * Component
 * 1: reset
 * 2: go fullscreen
 */

.sm-c-modal {
  accent-color: var(--sm-c-modal--AccentColor);
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  box-sizing: border-box;
  color: var(--sm-c-modal--Color);
  display: grid;
  font: var(--sm-c-modal--Font);
  grid-column-gap: var(--sm-c-modal--ColumnGap);
  grid: var(--sm-c-modal--Grid);
  min-height: 100%;  /* 2 */
  min-width: 100%;  /* 2 */
  padding: var(--sm-c-modal--Padding);
  position: fixed;
  text-align: center;
  visibility: hidden;
}

.sm-c-modal,
.sm-c-modal.sm-m-closing[open] {
  pointer-events: none;
  touch-events: none;
}

.sm-c-modal[open] {
  pointer-events: auto;
  touch-events: auto;
  visibility: visible;
}



/**
 * Backdrop
 * 1: dialog-polyfill
 */

.sm-c-modal::backdrop {
  display: none;
}

.sm-c-modal + .backdrop {  /* 1 */
  display: none;
}



/**
 * Mask
 * 1: center in the viewport
 */

.sm-c-modal::after {
  aspect-ratio: 1 / 1;
  background-color: var(--sm-c-modal__mask--BackgroundColor);
  content: "";
  display: block;
  left: 50%;  /* 1 */
  position: fixed;
  top: 50%;  /* 1 */
  transform: translateX(-50%) translateY(-50%);
  transition: clip-path var(--sm-c-modal__mask--TransitionDuration) var(--sm-c-modal__mask--TransitionTimingFunction);
  width: 100vmax;
  z-index: 0;
}

.sm-c-modal::after,
.sm-c-modal.sm-m-closing[open]::after {
  clip-path: circle(0);
}

.sm-c-modal[open]::after {
  clip-path: circle(calc(var(--sm-c-modal__mask--InlineSize) / 2));
}

@supports not (aspect-ratio: 1 / 1) {
  .sm-c-modal::after {
    height: 100vmax;
  }
}



/**
 * Background
 */

.sm-c-modal::before {
  content: "";
  display: block;
  inset: 0;
  position: absolute;
  transition: background-color var(--sm-c-modal__background--TransitionDuration) linear;
}

.sm-c-modal::before,
.sm-c-modal.sm-m-closing[open]::before {
  background-color: transparent;
  transition-delay: calc(var(--sm-c-modal__mask--TransitionDuration) / 2);
}

.sm-c-modal[open]::before {
  background-color: var(--sm-c-modal__background--BackgroundColor);
  transition-delay: 0s;
}

@supports not (inset: 0) {
  .sm-c-modal::before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}



/**
 * Foreground
 */

.sm-c-modal > :is(h1, div, .sm-c-modal-button, svg) {
  transition: opacity var(--sm-c-modal__foreground--TransitionDuration) linear;
  z-index: 1;
}

.sm-c-modal > :is(h1, div, .sm-c-modal-button, svg),
.sm-c-modal.sm-m-closing[open] > :is(h1, div, .sm-c-modal-button, svg) {
  opacity: 0;
  transition-delay: 0s;
}

.sm-c-modal[open] > :is(h1, div, .sm-c-modal-button, svg) {
  opacity: 1;
  transition-delay: var(--sm-c-modal__mask--TransitionDuration);
}



/**
 * Content
 * 1: reset
 */

.sm-c-modal > div {
  grid-area: content;
  justify-self: center;
  line-height: var(--sm-c-modal__content--LineHeight);
  margin-block-end: var(--sm-c-modal__content--MarginBlockEnd);
  max-inline-size: var(--sm-c-modal__content--MaxInlineSize);
}

.sm-c-modal > div > :is(:first-child, :last-child) {
  margin: 0;  /* 1 */
}

@supports not(max-inline-size: 0) {
  .sm-c-modal > div {
    margin-bottom: var(--sm-c-modal__content--MarginBlockEnd);
    max-width: var(--sm-c-modal__content--MaxInlineSize);
  }
}



/**
 * Close button
 */

.sm-c-modal .sm-c-modal-button.sm-m-close {
  align-self: var(--sm-c-modal__close--AlignSelf);
  grid-area: close;
  inset-block-start: 0;
  inset-inline-end: 0;
  justify-self: var(--sm-c-modal__close--JustifySelf);
  position: absolute;
}

@supports not (inset-block-start: 0) {
  .sm-c-modal .sm-c-modal-button.sm-m-close {
    right: 0;
    top: 0;
  }
}

@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal .sm-c-modal-button.sm-m-close {
    position: static;
  }
}



/**
 * Logo
 */

.sm-c-modal > svg {
  block-size: var(--sm-c-modal__logo--BlockSize);
  color: var(--sm-c-modal__logo--Color);
  display: none;
  inset-block-end: var(--sm-c-modal--Padding);
  inset-inline-end: var(--sm-c-modal--Padding);
  position: absolute;
}

@supports not (inset-block-end: 0) {
  .sm-c-modal > svg {
    height: var(--sm-c-modal__logo--BlockSize);
    bottom: var(--sm-c-modal--Padding);
    right: var(--sm-c-modal--Padding);
  }
}

/** Medium / Medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal > svg {
    display: block;
  }
}
