/** ***************************************************************************
 * Molecules / CMS plugins / Secret word modal
 *************************************************************************** */

.sm-c-modal.sm-m-secret {
  /**
   * Heading
   */

  --sm-c-modal--m-secret__heading--Color: var(--sm-global--palette-green);
  --sm-c-modal--m-secret__heading--MarginBlockEnd: 1rem;

  --sm-c-modal--m-secret__heading--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--sm) var(--sm-global--FontFamily-default);
  --sm-c-modal--m-secret__heading--md-md--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--md) var(--sm-global--FontFamily-default);

  --sm-c-modal--m-secret__heading--MarginBlockStart: 1.75rem;  /* 28px */
  --sm-c-modal--m-secret__heading--md-md--MarginBlockStart: 3.75rem;  /* 59px */
}

/** Inline: medium / Block: medium */
@media all and (min-width: 48rem) and (min-height: 37.5rem) {
  .sm-c-modal.sm-m-secret {
    --sm-c-modal--m-secret__heading--Font: var(--sm-c-modal--m-secret__heading--md-md--Font);
    --sm-c-modal--m-secret__heading--MarginBlockStart: var(--sm-c-modal--m-secret__heading--md-md--MarginBlockStart);
  }
}



/**
 * Component
 */

.sm-c-modal.sm-m-secret {
  --sm-c-modal--ColumnGap: 3.125rem;  /* 50px */
  --sm-c-modal--Grid:
    "close   close"   1fr
    "heading heading" auto
    "content content" auto
    "answer  peek"    1fr
  / 1fr      1fr;
}



/**
 * Heading
 */

.sm-c-modal.sm-m-secret > h1 {
  color: var(--sm-c-modal--m-secret__heading--Color);
  font: var(--sm-c-modal--m-secret__heading--Font);
  grid-area: heading;
  line-height: var(--sm-c-modal--m-secret--LineHeight);
  margin-block-end: var(--sm-c-modal--m-secret__heading--MarginBlockEnd);
  margin-block-start: var(--sm-c-modal--m-secret__heading--MarginBlockStart);
}

@supports not (margin-block-end: 0) {
  .sm-c-modal.sm-m-secret > h1 {
    margin-bottom: var(--sm-c-modal--m-secret__heading--MarginBlockEnd);
    margin-top: var(--sm-c-modal--m-secret__heading--MarginBlockStart);
  }
}



/**
 * Button icon
 */

.sm-c-modal.sm-m-secret .sm-c-modal-button > svg {
  overflow: visible;
}



/**
 * Answer button
 */

.sm-c-modal.sm-m-secret .sm-c-modal-button:nth-of-type(2) {
  align-self: start;
  grid-area: answer;
  justify-self: end;
}



/**
 * Peek button
 */

.sm-c-modal.sm-m-secret .sm-c-modal-button:nth-of-type(3) {
  align-self: start;
  grid-area: peek;
  justify-self: start;
}
