/** ***************************************************************************
 * Atoms / Button / Modal / Base
 *************************************************************************** */

.sm-c-modal-button {
  --sm-c-modal-button--Color: currentcolor;
  --sm-c-modal-button--Font: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--sm) var(--sm-global--FontFamily-default);
  --sm-c-modal-button--RowGap: .43em;  /* 6px */


  /**
   * Icon
   */

  --sm-c-modal-button__icon--Color: var(--sm-c-page--BackgroundColor);
  --sm-c-modal-button__icon--BlockSize: 2.625em;  /* 42px */
}

/** Medium */
@media all and (min-width: 48rem) {
  .sm-c-modal-button {
    --sm-c-modal-button--GridAutoFlow: var(--sm-c-modal-button--md--GridAutoFlow);
  }
}



/**
 * Component
 * 1: reset
 * 2: disabled, see Icon (1)
 */

.sm-c-modal-button {
  /* row-gap: var(--sm-c-modal-button--RowGap); */ /* 2 */
  align-items: center;
  appearance: none;  /* 1 */
  background-color: transparent;  /* 1 */
  border: 0;  /* 1 */
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font: var(--sm-c-modal-button--Font);
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
  pointer-events: auto;
  text-transform: uppercase;
  touch-events: auto;
}

.sm-c-modal-button:focus {
  outline: 0;  /* 1 */
}



/**
 * Radio button
 * 1: hide
 */

.sm-c-modal-button input {
  opacity: 0;  /* 1 */
  pointer-events: none;  /* 1 */
  position: absolute;  /* 1 */
  touch-events: none;  /* 1 */
}



/**
 * Icon
 * 1: simulate flex row-gap
 */

.sm-c-modal-button > :first-child {
  margin-block-end: var(--sm-c-modal-button--RowGap);  /* 1 */
}

.sm-c-modal-button svg {
  --sm-c-icon--BackgroundColor: currentcolor;
  --sm-c-icon--BorderColor: currentcolor;
  --sm-c-icon--Color: var(--sm-c-modal-button__icon--Color);
  height: var(--sm-c-modal-button__icon--BlockSize);
  width: var(--sm-c-modal-button__icon--BlockSize);
}

.sm-c-modal-button:focus > :first-child {
  animation: var(--sm-global--Animation--pulse);
}

@supports not (margin-block-end: 0) {
  .sm-c-modal-button > :first-child {
    margin-bottom: var(--sm-c-modal-button--RowGap);  /* 1 */
  }
}
