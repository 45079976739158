/** ***************************************************************************
 * Atoms / Button / Black / Contacts
 *************************************************************************** */

@keyframes sm-c-button-black--m-contacts__airplane--AnimationName {
  0%, 100% {
    transform: initial;
  }
  50% {
    transform: translateX(100%) translateY(-100%);
  }
  51% {
    transform: translateX(-100%) translateY(100%);
  }
}

.sm-c-button-black {
  --sm-c-button-black--m-contacts__airplane--AnimationDuration: var(--sm-global--Duration--lg);
}



/**
 * Airplane
 */

.sm-c-button-black.sm-m-contacts:hover > svg > path {
  animation:
    sm-c-button-black--m-contacts__airplane--AnimationName
    var(--sm-c-button-black--m-contacts__airplane--AnimationDuration)
    var(--sm-c-button-black__icon--TransitionDuration)
    ease;
}
